<template>
  <div>
    <div v-show="dialogVisible" id="dragBox" ref="dragBox" class="layerBox">
      <div class="title" @mousedown="move">
        <div>
          <span class="title-content">
            <!-- {{stationName}} -->定位
          </span>
          <span class="caoz">
            <i
              class="iconfont icon-shanchu2"
              style="float: right; padding-right: 10px;"
              @click="colse()"
            ></i>
            <i class="iconfont icon-yidong" style="float: right; padding-right:13px"></i>
          </span>
        </div>
      </div>
      <div>
       <div id="mapAdd" style="width: 100%;height: 800px">
      
       </div>
        <location-lengd ref="LocationLengd"></location-lengd>
      </div>
    </div>
    <div ref="Info" class="Bubble" >
      <div  class="markbox">
        <div v-if="item.showType === 'eq'" class="mainBox">
          <div class="markTitle">
            <p>
              <span>{{ item.title }}</span>
            </p>
            <i class="el-icon-error" @click="colseWindow()"></i>
          </div>

          <div class="Feature line-mark">
            <div class="contentList">
             <el-form
              ref="formEq"
              label-width="100px"
              size="mini"
            >
              <el-row>
                <el-col>
              <el-form-item label="检测点名称:">
                    {{item.pointName}}
                </el-form-item>
                </el-col>
                <el-col>
                <el-form-item label="所属图层:">
                {{item.layerName}}
                  
                </el-form-item>
                </el-col>
                <el-col>
                <el-form-item label="设备编号:">
                {{item.flowCode}}
                
                </el-form-item>

              </el-col>
                <el-col :span="12">
                <el-form-item label="设备类型:" prop="eqType">
                  {{item.eqType==11?'流量计':'压力计'}}
                </el-form-item>
                </el-col>
                <el-col :span="12">
                <el-form-item label="设备状态:" prop="generalStatus">
                  {{item.generalStatusName}}
                </el-form-item>
                </el-col>
                <el-col :span="12">
                <el-form-item label="规格:">
                  {{item.spec}}
                </el-form-item>
                </el-col>
                <el-col :span="12">
                <el-form-item label="型号:">
                  {{item.model}}
                </el-form-item>
                </el-col>
                <el-col :span="12">
                <el-form-item label="安装日期:" prop="installDate">
                  {{item.installDate}}
                </el-form-item>
                </el-col>
                <el-col :span="12">
                <el-form-item label="是否远传:" >
                  {{item.transmitName}}
                </el-form-item>
                </el-col>
                <el-col :span="12">
                <el-form-item label="是否在线:" >
                  {{item.onlineName}}
                  
                </el-form-item>
                </el-col>
                <el-col :span="12">
                <el-form-item label="厂家:">
                  {{item.factory}}
                </el-form-item>
                </el-col>
                <el-col :span="12">

                <el-form-item label="附着压力级别:" >
                  {{item.pressureLevelName}}
                  
                </el-form-item>
              </el-col>
                <el-col :span="24">
                <el-form-item label="备注:">
                  {{item.remark}}
                
                </el-form-item>
              </el-col>
                <el-col :span="24">
                
                <el-form-item label="经纬度:">
                  {{item.point}}
                
                </el-form-item>
                </el-col>
                </el-row>
              </el-form>
            </div>
          </div>
        </div>
        <div v-if="item.showType ==='hiddenDesc'" class="mainBox">
          <div class="markTitle">
            <p>
              <span>{{ item.layerName }}</span>
            </p>
            <i class="el-icon-error" @click="colseWindow()"></i>
          </div>

          <div class="Feature line-mark">
            <div class="contentList">
              <el-form  label-position="right" label-width="80px" size="mini">
                <el-form-item label="名称:">
                  <div class="equipment-item">
                    {{item.typeName}}-{{ item.name }}
                  </div>
                </el-form-item>
                <el-form-item label="坐标:">
                  <div class="equipment-item">
                    {{ item.point }}
                  </div>
                </el-form-item>
                <el-form-item label="埋深(m):">
                  <div class="equipment-item">
                    {{ item.depth }}
                  </div>
                </el-form-item>
               
                <el-form-item label="备注:">
                  <div class="equipment-item">
                    {{ item.remarks }}
                  </div>
                </el-form-item>
                <el-form-item label="工程照片:">
                  <div class="equipment-item">
                    <img :src="itemImg" v-for="itemImg,index in item.urlList" :key="index" style="width:80px;height:80px;margin-left:10px"/>
                  </div>
                </el-form-item>

              </el-form>
            </div>
            <div class="footer" v-if="$parent.gis">
              
              <el-button
                type="danger"
                class="but"
                size="mini"
                @click="equipmentDel(item.id, item.equipmentType)"
                >移 除</el-button
              >
              <el-button
                type="primary"
                class="but"
                size="mini"
                @click="editHidden(item, item.equipmentType)"
                >编 辑</el-button
              >
            </div>
          </div>
        </div>
        <div v-if="item.showType === 'valve'">
          <div class="markTitle">
            <p>
              <span>{{ item.layerName }}</span>
            </p>
            <i class="el-icon-error" @click="colseWindow()"></i>
          </div>

          <div class="Feature line-mark">
            <div class="contentList">
              <el-form
                class="blueFormLabelClass"
                label-position="right"
                :inline="true"
                style="width:580px"
                label-width="90px"
                size="mini"
              >
                <el-form-item label="所属图层:">
                  <div class="equipment-item-textarea">{{ item.layerName }}</div>
                </el-form-item>
                <el-form-item label="设备编号:">
                  <div class="equipment-item-textarea">
                    {{ item.valveCode }}
                  </div>
                </el-form-item>
                <el-form-item label="经纬度:">
                  <div class="equipment-item-textarea">{{ item.point }}</div>
                </el-form-item>
                <el-form-item label="设备名称:">
                  <div class="equipment-item-textarea">{{ item.valveName }}</div>
                </el-form-item>
                <el-form-item label="阀门种类:">
                  <div class="equipment-item">{{ item.valveKindName }}</div>
                </el-form-item>
                <el-form-item label="敷设方式:">
                  <div class="equipment-item">{{ item.layTypeName }}</div>
                </el-form-item>
                <el-form-item label="阀门设施:">
                  <div class="equipment-item">{{ item.valveFacilitiesName }}</div>
                </el-form-item>
                <el-form-item label="阀井深度:"  v-if="item.valveFacilities =='2'">
                  <div class="equipment-item">{{ item.valveWellDepth }}</div>
                </el-form-item>
                <el-form-item label="阀井类型:"  v-if="item.valveFacilities =='2'">
                  <div class="equipment-item">{{ item.valveTypeName }}</div>
                </el-form-item>
                <el-form-item label="埋深:">
                  <div class="equipment-item">{{ item.depth }}</div>
                </el-form-item>
                <el-form-item label="规格:">
                  <div class="equipment-item">{{ item.spec }}</div>
                </el-form-item>
                <el-form-item label="型号:">
                  <div class="equipment-item">{{ item.model }}</div>
                </el-form-item>
                <el-form-item label="设备状态:">
                  <div class="equipment-item">{{ item.generalStatusName }}</div>
                </el-form-item>
                <el-form-item label="连接方式:">
                  <div class="equipment-item">{{ item.connectTypeName }}</div>
                </el-form-item>
               
                <el-form-item label="安装时间:">
                  <div class="equipment-item">{{ item.installDate }}</div>
                </el-form-item>
                <el-form-item label="附着压力级别:">
                  <div class="equipment-item">{{ item.pressureLevelName }}</div>
                </el-form-item>
                <el-form-item label="所在街道:">
                  <div class="equipment-item-textarea">{{ item.road }}</div>
                </el-form-item>
                <el-form-item label="生产厂家:">
                  <div class="equipment-item-textarea">{{ item.factory }}</div>
                </el-form-item>
                <el-form-item label="备注:">
                  <div class="equipment-item-textarea">{{ item.remark }}</div>
                </el-form-item>
                <el-form-item label="所在地址:">
                  <div class="equipment-item-textarea">{{ item.address }}</div>
                </el-form-item>
                
              </el-form>
            </div>
          </div>
        </div>
         <div v-if="item.showType === 'line'">
          <div class="markTitle">
            <p>
              <span>{{item.pipeName}}</span>
            </p>
            <i class="el-icon-error" @click="colseWindow()"></i>
          </div>

          <div class="Feature line-pipe">
            <div class="contentList">
              <el-form
                class="blueFormLabelClass"
                label-position="left"
                :inline="true"
                style="width:580px"
                label-width="82px"
                size="mini"
              >
                <el-form-item label="所属图层:">
                  <div class="equipment-item-textarea">{{ item.layerName }}</div>
                </el-form-item>
                <el-form-item label="管道编号:">
                  <div class="equipment-item-textarea">{{ item.pipeCode }}</div>
                </el-form-item>
                <el-form-item label="管道名称:">
                  <div class="equipment-item-textarea">{{ item.pipeName }}</div>
                </el-form-item>
                <el-form-item >
                  <label slot="label">管&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;材:</label>
                  <div class="equipment-item">{{ item.pipeTypeName }}</div>
                </el-form-item>
                <el-form-item label="管材型号:">
                  <div class="equipment-item">{{ item.pipeModelName }}</div>
                </el-form-item>
                
                <el-form-item label="公称直径:">
                  <div class="equipment-item">{{ item.diameterName }}</div>
                </el-form-item>
                <el-form-item label="实际外径:">
                  <div class="equipment-item">{{ item.outerDiameter }}(mm)</div>
                </el-form-item>
                <el-form-item label="壁厚mm:">
                  <div class="equipment-item">{{ item.thickness }} (mm)</div>
                </el-form-item>
                <el-form-item label="实际内径:">
                  <div class="equipment-item">{{ item.innerDiameter }} (mm)</div>
                </el-form-item>
                 <el-form-item label="粗糙度:">
                  <label slot="label">粗&nbsp;&nbsp;糙&nbsp;&nbsp;度:</label>
                  <div class="equipment-item">{{ item.roughness }}</div>
                </el-form-item>
                <el-form-item label="埋深:">
                  <label slot="label">埋&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;深:</label>
                  <div class="equipment-item">{{ item.depth }}</div>
                </el-form-item>
                <el-form-item label="压力级别:">
                  <div class="equipment-item">{{ item.pressureLevelName }}</div>
                </el-form-item>
                <el-form-item label="设计压力:">
                  <div class="equipment-item">{{ item.designPressure }}</div>
                </el-form-item>
                <el-form-item label="运行压力:">
                  <div class="equipment-item">{{ item.operationPressure }}</div>
                </el-form-item>
                <el-form-item label="管道长度:">
                  <div class="equipment-item">{{ item.pipeLength }} (m)</div>
                </el-form-item>
                <el-form-item label="建设日期:">
                  <div class="equipment-item">{{ item.buildDate }}</div>
                </el-form-item>
                <el-form-item label="燃气分类:">
                  <div class="equipment-item">{{ item.gasTypeName }}</div>
                </el-form-item>
                <el-form-item label="状态:">
                  <div class="equipment-item">{{ item.pipeStatusName }}</div>
                </el-form-item>
                <el-form-item label="敷设方式:">
                  <div class="equipment-item">{{ item.buildTypeName }}</div>
                </el-form-item>
               <el-form-item label="确认状态:">
                  <div class="equipment-item">{{ item.confirmStatusCodeName }}</div>
                </el-form-item>
                <el-form-item label="下次检测日期:" label-width="100px">
                  <div class="equipment-item" style="width:160px">{{ item.nextMonitorDate }}</div>
                </el-form-item>
                <el-form-item label="所在道路:">
                  <div class="equipment-item-textarea">{{ item.road }}</div>
                </el-form-item>
                
                <el-form-item label="建设单位:">
                  <div class="equipment-item-textarea">{{ item.buildOrganization }}</div>
                </el-form-item>
                <el-form-item label="设计单位:">
                <div class="equipment-item-textarea">{{ item.designOrganization }}</div>
              </el-form-item>
                <el-form-item label="备注:">
                  <label slot="label">备&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;注:</label>
                  <div class="equipment-item-textarea">{{ item.remark }}</div>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
         <div v-if="item.showType === 'userSta'||item.showType ==='station'">
          <div class="markTitle">
            <p>
              <span>{{item.title}}</span>
            </p>
            <i class="el-icon-error" @click="colseWindow()"></i>
          </div>

          <div class="Feature line-pipe">
            <div class="contentList">
              <el-form
                class="blueFormLabelClass"
                label-position="left"
                :inline="true"
                style="width:580px"
                label-width="102px"
                size="mini"
              >
                 <el-form-item label="所属图层:">
                <div class="equipment-item-textarea">{{ item.layerName }}</div>
              </el-form-item>
              <el-form-item label="名称:">
                <div class="equipment-item-textarea">{{ item.stationName }}</div>
              </el-form-item>
              <el-form-item label="场站分类:" >
                <div class="equipment-item">{{ item.stationKindName }}</div>
              </el-form-item>
              <!-- <el-form-item label="设计规模:">
                <div class="equipment-item">{{ item.scale }}(Nm³/h)</div>
              </el-form-item> -->
              <el-form-item label="位置:">
                <div class="equipment-item">{{ item.address }}</div>
              </el-form-item>
              <el-form-item label="经纬度:">
               <div class="equipment-item">{{ item.point }}</div> 
              </el-form-item>
              <!-- <div v-if="item.stationType==2">
               <el-form-item label="用户数量(户):">
                <div class="equipment-item">{{ item.userCount }}</div>
              </el-form-item>
              <el-form-item label="居民(户):">
               <div class="equipment-item"> {{ item.residentCount }}</div>
              </el-form-item>
              <el-form-item label="商业(户):">
               <div class="equipment-item">{{ item.businessCount }}</div>
              </el-form-item>
              <el-form-item label="工业(户):">
                <div class="equipment-item">{{ item.industryCount }}</div>
              </el-form-item>
              </div> -->
              <el-form-item label="备注:">
                <div class="equipment-item">{{ item.remark }}</div>
              </el-form-item>
              </el-form>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
</template>
<script>
import '@/views/Gis/style/skin.scss'
import { formatLocation } from '@/views/Gis/utils/geoTool'
import LocationLengd from '@/views/gisbook/locationLengd.vue'

export default {
  components: {
    LocationLengd
  },
  data() {
    return {
    LocationLengdogVisible: false,
      dialogFormVisible:false,
      stationName: '',
      stationId:'',
      form: {},
      breadth: "width:350px",
      tableData:[],
      markerHighlight:null,
      map:null,
      infoWindow:null,
      item:{},
      dialogVisible:false,
      markerHighlightA:null
    }
  },
  mounted() {
    this.openDialog()
  },
  methods: {
    openDialog(obj,lonlat) {
      let position = formatLocation(lonlat,'Point')
      this.$nextTick(() => {
        if(!this.map){
          this.map = new AMap.Map("mapAdd", {
          resizeEnable: true,
          center:position,
          zoom: 14,
          pitch: 0,
          rotation: 0,
          zoom: 11,
          zooms: [0, 20],
          maxZoom: 20,
          labelzIndex: 1,
          pitchEnable: false,
          rotateEnable: false,
          expandZoomRange: true, //调整最大缩放级数
          showBuildingBlock: false,
          animateEnable: false,
          jogEnable: false,
        });
        this.$refs.LocationLengd.getwms()
        }
        if(obj.showType === 'line'){
          if (this.markerHighlightA) {
              this.map.remove(this.markerHighlightA)
            }
            this.markerHighlightA = new  AMap.Polyline({
                  path: obj.lineArray,
                  isOutline: true,
                  outlineColor: '#1A5F48',
                  borderWeight: 3,
                  strokeColor: '#00FF7F',
                  strokeOpacity: 1,
                  strokeWeight: 3,
                  strokeStyle: 'solid',
                  lineJoin: 'round',
                  lineCap: 'round',
                  zIndex: 150,
                  extData: ''
                })
            this.map.add(this.markerHighlightA)
        }else{
            if (this.markerHighlight) {
              this.map.remove(this.markerHighlight)
            }
            this.markerHighlight = new AMap.Marker({
              content:
                '<div> <div class="scatterPoint scatterPoint2"></div>' +
                '<div class="scatterPoint scatterPoint3" ></div></div>',
              offset: new AMap.Pixel(0, 0),
              position: position,
              extData: ''
            })
            this.map.add(this.markerHighlight)
        }
      

      
      this.openEquipmentInfo(obj,position)
      })
      // this.getList(obj.id)
      // this.stationId = obj.id
      // this.stationName = obj.stationName
       this.dialogVisible = true
    },
    colse() {
      this.dialogVisible = false
      if(this.infoWindow){
        this.infoWindow.close()
      }
    }, 
     colseWindow(){
      if(this.infoWindow){
        this.infoWindow.close()
      }
     },
    openEquipmentInfo(feat, lnglat) {
      this.lnglat = lnglat;
      let html = this.$refs.Info
      let infoWindow = new AMap.InfoWindow({
        position: lnglat,
        offset: new AMap.Pixel(10, -45),
      //  anchor: 'top-left',
        content: html
      })
      this.initialize({
        item: feat,
        infoWindow: infoWindow
      })
      infoWindow.open(this.map)
      
    },
    initialize(e) {
      this.item = e.item
      this.infoWindow = e.infoWindow
    },
   
 
    
    move(e) {
      let odiv = this.$refs.dragBox
      let disX = e.clientX - odiv.offsetLeft
      let disY = e.clientY - odiv.offsetTop
      document.onmousemove = e => {
        let left = e.clientX - disX
        let top = e.clientY - disY
        this.positionX = top
        this.positionY = left

        odiv.style.left = left + 'px'
        odiv.style.top = top + 'px'
      }
      document.onmouseup = e => {
        document.onmousemove = null
        document.onmouseup = null
      }
    }    
  }
}
</script>

<style lang="scss" scoped>
.Bubble{background: #fff;}
::v-deep .el-tabs__item.is-active {
  color: var(--font-color-f1);
  background-color: var(--background-color-skin);
}
::v-deep .el-tabs__item {
  color: var(--font-color-c1);
}
::v-deep .el-tabs__active-bar {
  background-color: #6eb7e3;
}
::v-deep .el-tabs__nav-wrap::after {
  content: none;
}
::v-deep .el-tabs__header {
  margin: 0px;
}
::v-deep .el-button--text {
  background: transparent !important;
  border: none;
}
::v-deep .formItemSmall.el-form-item {
  margin-bottom: 2px;
}
.caozu {
  display: inline-flex;
  height: 40px;
  font-size: 14px;
  text-align: right;
  vertical-align: text-bottom;
  line-height: 36px;
  color: #a5b4c4;
  float: right;
}
.colseBut {
  font-size: 26px;
  vertical-align: middle;
  cursor: pointer;
}

.layerBox {
  width: 1526px;
  position: absolute;
  top: 80px;
  left: 250px;
  min-height: 3rem;
  min-width: 3rem;
  //   border: 1px solid var(--border-color-skin);
  box-shadow: 0px 1px 6px 0px rgba(4, 57, 138, 0.3);
  z-index: 2000;
  background: var(--background-color-skin);
  opacity: 0.98;
}
.Feature {
  padding: 5px 20px 10px;
  line-height: 0.28rem;
  // text-align: center;
  width: 500px;
  color: var(--font-color-f1);
}
.equipment-item {
  text-align: left;
  width: 380px;
  word-break: break-word;
}
.btnDiv {
  margin-bottom: 15px;
  .btn {
    margin-right: 15px;
  }
}
.formbtn {
  text-align: right;
  margin-right: 30px;
  button {
    margin: 7px 0px 0 15px;
  }
}
.btnText {
  color: var(--font-color-f1);
  padding: 4px 5px;
}
/* table按钮颜色 */
.btnText.danger {
  color: #f74b4b;
}
.btnText.danger:hover,
.btnText.danger:focus {
  color: #f78989;
}
.selecttag {
  margin-left: 5px;
  cursor: pointer;
}
.selectWidth {
  width: 380px;
}
.title {
  height: 40px;
  line-height: 40px;
  background: linear-gradient(90deg, var(--background-title-skin1), var(--background-title-skin2));
}
::v-deep .el-tabs__nav-scroll {
  background: linear-gradient(90deg, var(--background-title-skin1), var(--background-title-skin2));
  padding-left: 10px;
  padding-top: 6px;
}
::v-deep .el-tabs__item {
  height: 39px;
  line-height: 39px;
  text-align: center;
  min-width: 110px;
  color: var(--font-color-f1);
}
::v-deep .el-tabs__item.is-active {
  border-radius: 4px 4px 0px 0px;
  text-align: center;
  color: var(--font-color-f1);
}
::v-deep .el-tabs__active-bar {
  background-color: #fff;
}
.title-content {
  color: var(--font-color-f1);
  font-weight: bold;
  padding: 13px 9px;
  font-size: 14px;
}
.caozt {
  position: absolute;
  right: 0px;
  top: 10px;
}
::v-deep .el-tabs--border-card {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
::v-deep .el-tabs--border-card > .el-tabs__content {
  padding: 0px;
}
::v-deep .el-form .el-select {
  width: 380px;
}
::v-deep .el-form-item--mini.el-form-item, .el-form-item--small.el-form-item{margin-bottom: 2px;}
::v-deep .amap-info-close{display: none;}
::v-deep .amap-info-content{padding: 0px;}
.markTitle {
  height: 42px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // background-color: var(--background-title-skin);
   background: linear-gradient(90deg, rgba(40, 95, 136,0.3),rgba(40, 95, 136, 0));

  p {
    display: flex;
    line-height: 1;
    justify-content: flex-start;
    align-items: center;
    margin-left: 21px;
    font-size: 14px;
    font-weight: bold;
  }
  i {
    font-size: 18px;
    color: #679db4;
    cursor: pointer;
    margin-right: 10px;
  }
  i:hover {
    color: #548aa1;
  }
}
.equipment-item {
  text-align: left;
  width: 180px;
  color: var(--font-color-c2);
}
.equipment-item-textarea {
  text-align: left;
  width: 450px;
}
</style>
